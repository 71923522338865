function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/* eslint-disable complexity */
import _ from 'lodash';
import { baseUtils as utils } from '../utils/baseUtils';
import Consts from '../utils/consts';
export var parseStyleParams = function parseStyleParams(styles, isStoreGallery, isMobile) {
  if (isStoreGallery === void 0) {
    isStoreGallery = false;
  }

  if (isMobile === void 0) {
    isMobile = utils.isMobile();
  }

  var flatStyles = styles && (styles.numbers || styles.colors || styles.booleans || styles.fonts) ? _objectSpread(_objectSpread(_objectSpread(_objectSpread({}, styles.numbers), styles.colors), styles.booleans), styles.fonts) : styles;
  var styleParams = {
    allowDownload: false,
    allowSocial: true,
    loveButton: true,
    isStoreGallery: isStoreGallery,
    loveCounter: true,
    allowTitle: true,
    allowTitleExpand: true,
    allowDescriptionExpand: true,
    allowLinkExpand: true,
    allowFullscreenExpand: true,
    allowExifExpand: false,
    defaultShowInfoExpand: true,
    showInfoExpandButton: true,
    expandInfoPosition: 'SIDE',
    addToCartButtonText: '',
    galleryAlignExpand: 'left',
    fullscreenLoop: false,
    mobileSwipeAnimation: Consts.mobileSwipeAnimations.EXPAND
  };

  if (isMobile) {
    flatStyles = convertToMobileSettingIfNeeded(flatStyles);
  }

  _.merge(styleParams, _.pick(flatStyles, ['isRTL', 'isAccessible', 'actionsColorExpand', 'addToCartColorExpand', 'addToCartBackColorExpand', 'addToCartBorderColor', 'addToCartFontExpand', 'addToCartBorderWidth', 'bgColorExpand', 'titleColorExpand', 'descriptionColorExpand', 'titleFontExpand', 'descriptionFontExpand', 'allowDownload', 'allowSocial', 'loveButton', 'allowTitle', 'allowTitleExpand', 'allowDescriptionExpand', 'allowExifExpand', 'allowLinkExpand', 'allowFullscreenExpend', 'addToCartButtonText', 'fullscreenLoop', 'expandInfoPosition']));

  if (!_.isUndefined(flatStyles.expandInfoPosition)) {
    styleParams.expandInfoPosition = flatStyles.expandInfoPosition ? 'BOTTOM' : 'SIDE';
  }

  if (!_.isUndefined(flatStyles.loveCounter)) {
    styleParams.loveCounter = flatStyles.loveCounter === 0;
  }

  if (!_.isUndefined(flatStyles.galleryAlignExpand)) {
    switch (flatStyles.galleryAlignExpand) {
      case 0:
      default:
        styleParams.galleryAlignExpand = 'left';
        styleParams.galleryAlignExpandIcons = {
          "float": 'left'
        };
        break;

      case 1:
        styleParams.galleryAlignExpand = 'center';
        styleParams.galleryAlignExpandIcons = {
          margin: 'auto'
        };
        break;

      case 2:
        styleParams.galleryAlignExpand = 'right';
        styleParams.galleryAlignExpandIcons = {
          "float": 'right'
        };
        break;
    }
  }

  if (!_.isUndefined(flatStyles.videoSpeed)) {
    styleParams.videoSpeed = flatStyles.videoSpeed;
  }

  if (!_.isUndefined(flatStyles.videoLoop)) {
    styleParams.videoLoop = flatStyles.videoLoop;
  }

  if (!_.isUndefined(flatStyles.mobileSwipeAnimation)) {
    if (String(flatStyles.mobileSwipeAnimation) === '1') {
      styleParams.mobileSwipeAnimation = Consts.mobileSwipeAnimations.FADE;
    } else if (String(flatStyles.mobileSwipeAnimation) === '2') {
      styleParams.mobileSwipeAnimation = Consts.mobileSwipeAnimations.CAROUSEL;
    } else {
      // String(flatStyles.mobileSwipeAnimation) === '0'
      styleParams.mobileSwipeAnimation = Consts.mobileSwipeAnimations.EXPAND;
    }
  }

  if (!isMobile) {
    if (!_.isUndefined(flatStyles.defaultShowInfoExpand)) {
      styleParams.defaultShowInfoExpand = flatStyles.defaultShowInfoExpand === 1 || flatStyles.expandInfoPosition;
      styleParams.showInfoExpandButton = flatStyles.defaultShowInfoExpand === 0 && !flatStyles.expandInfoPosition;
    } else {
      styleParams.showInfoExpandButton = false;
    }
  } else {
    styleParams.defaultShowInfoExpand = false;
  }

  if (!_.isUndefined(flatStyles.addToCartButtonText)) {
    styleParams.addToCartButtonText = String(flatStyles.addToCartButtonText);
  }

  if (!_.isUndefined(flatStyles.allowFullscreenExpand)) {
    styleParams.allowFullscreenExpand = flatStyles.allowFullscreenExpand;
  }

  if (!_.isUndefined('itemClick')) {
    if (typeof flatStyles.itemClick === 'number') {
      switch (flatStyles.itemClick) {
        case 0:
        default:
          styleParams.itemClick = 'expand';
          break;

        case 1:
          styleParams.itemClick = 'link';
          break;

        case 2:
          styleParams.itemClick = 'nothing';
          break;

        case 3:
          styleParams.itemClick = 'fullscreen';
          break;
      }
    } else if (flatStyles.itemClick === 'expand' || flatStyles.itemClick === 'link' || flatStyles.itemClick === 'nothing' || flatStyles.itemClick === 'fullscreen') {
      styleParams.itemClick = flatStyles.itemClick;
    } else {
      styleParams.itemClick = 'expand';
    }
  }

  styleParams.sharpParams = {
    quality: 90,
    usm: {}
  };

  if (!_.isUndefined(flatStyles.usmToggle) && flatStyles.usmToggle === true) {
    styleParams.sharpParams.allowUsm = flatStyles.usmToggle;

    if (!_.isUndefined(flatStyles.imageQuality)) {
      styleParams.sharpParams.quality = flatStyles.imageQuality;
    }

    if (!_.isUndefined(flatStyles.usm_a)) {
      styleParams.sharpParams.usm.usm_a = (flatStyles.usm_a || 0) / 100;
    }

    if (!_.isUndefined(flatStyles.usm_r)) {
      styleParams.sharpParams.usm.usm_r = flatStyles.usm_r;
    }

    if (!_.isUndefined(flatStyles.usm_t)) {
      styleParams.sharpParams.usm.usm_t = (flatStyles.usm_t || 0) / 255;
    }
  }

  return styleParams;
};

function convertToMobileSettingIfNeeded(styles) {
  Object.keys(styles).forEach(function (val) {
    if (val.startsWith('m_')) {
      styles[val.slice(2)] = styles[val];
    }
  });
  return styles;
}